import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { Image } from '@wix/image';
import { getImageUri, getOuterUrl } from '@wix/communities-blog-client-common';
import Link from '../../../common/components/link/internal-link';
import { PostListItemVideoThumbnail } from '../../../common/components/post-list-item-video-thumbnail';
import withDeviceType from '../../../common/hoc/with-device-type';
import withPermissions from '../../../common/hoc/with-permissions';
import withSectionUrl from '../../../common/hoc/with-section-url';
import styles from './recent-post-list-item-cover.scss';

const RecentPostListItemCover = ({
  can,
  post,
  postLink,
  imageMetadata,
  videoMetadata,
  sectionUrl,
}) => {
  const isPublic = can('share', 'post', post);

  return (
    <Link tabIndex="-1" fullRoute={post.link} to={postLink}>
      {imageMetadata ? (
        <div className={styles.coverWrapper}>
          <Image
            uri={getImageUri(imageMetadata)}
            className={styles.cover}
            targetWidth={450}
            targetHeight={253}
            socialAttrs={
              isPublic
                ? {
                    'data-pin-url':
                      post.link || getOuterUrl(postLink, sectionUrl),
                  }
                : { 'data-pin-nopin': true }
            }
          />
        </div>
      ) : (
        <div className={styles.thumbContainer}>
          <div className={styles.thumbWrapper}>
            <PostListItemVideoThumbnail
              oembed={videoMetadata}
              withoutLabel={true}
            />
          </div>
        </div>
      )}
    </Link>
  );
};

RecentPostListItemCover.propTypes = {
  can: PropTypes.func,
  post: PropTypes.object,
  postLink: PropTypes.string,
  imageMetadata: PropTypes.object,
  isMobile: PropTypes.bool,
  videoMetadata: PropTypes.object,
  sectionUrl: PropTypes.string,
};

export default flowRight(
  withDeviceType,
  withPermissions,
  withSectionUrl,
)(RecentPostListItemCover);
